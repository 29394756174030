const IconPlus = ({ fill = '#303333', className, dataTestid = 'icon-plus' }) => (
  <svg
    data-testid={dataTestid}
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_717_22093"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="30"
      height="30"
    >
      <rect x="0.601562" y="0.599609" width="28.8" height="28.8" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_717_22093)">
      <path
        d="M13.8016 16.1996H6.60156V13.7996H13.8016V6.59961H16.2016V13.7996H23.4016V16.1996H16.2016V23.3996H13.8016V16.1996Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default IconPlus;
